<template>
    <div>
        <div class="myNFTs">
            <div class="tabBar">
                <div class="tabBar-title">Market</div>
                <div class="tabBar-box">
                    <div class="Single Series" :class="{ currentIndexClass: currentIndex == 2 }" @click="btn(2)">
                        Single
                    </div>
                    <div class="Series" :class="{ currentIndexClass: currentIndex == 0,'disabled':$store.getters.walletInfo.chainId!==1 }" @click="$store.getters.walletInfo.chainId==1?btn(0):''">
                        Series
                    </div>
                    <div class="Series" :class="{ currentIndexClass: currentIndex == 1,'disabled':$store.getters.walletInfo.chainId!==1 }" @click="$store.getters.walletInfo.chainId==1?btn(1):''" style="margin-right:0">
                        Lastest Series NFT
                    </div>
                </div>
            </div>
            <div id="seriesBox" v-show="currentIndex == 0" v-infinite-scroll="scrollBtn" infinite-scroll-disabled="busy" infinite-scroll-distance="50">
                <div class="series-list">
                    <div class="series-li" v-for="(item, index) in seriesList" :key="index" @click="goMintList(item.seriesid,item.admin,item.tokenaddress)">
                        <div class="series-list-img-box">
                            <div class="series-list-img1">
                                <img v-lazy="`https://ipfs.io/ipfs/${item.serieslogo}`" />
                            </div>
                            <div class="series-list-img-box1">
                                <img v-lazy="`https://ipfs.io/ipfs/${item.seriesbanner}`" class="series-list-img2" />
                            </div>
                        </div>
                        <div class="series-list-text-box">
                            <div class="series-list-name">{{ item.seriesname }}</div>
                            <div class="series-list-name1">
                                <span class="by-name">by</span><span class="name-info">{{ seriesAdmin }}</span>
                            </div>
                            <div class="series-list-text">{{ item.description }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="marketBox" v-show="currentIndex == 1" v-infinite-scroll="scrollBtn1" infinite-scroll-disabled="busy1" infinite-scroll-distance="50">
                <div class="historyList">
                    <div class="historyLi" v-for="(item, index) in seriesList1" :key="index">
                        <div @click="goHistory1(item)">
                            <div class="history-img"><img v-lazy="item.image" /></div>
                            <div class="history-information-box">
                                <div class="history-information1">
                                    <div class="history-name">{{ item.title }}</div>
                                    <div class="history-price">
                                        <div class="history-price-logo">
                                            <img :src="chainImg[$store.getters.walletInfo.chainId]">
                                        </div>
                                        <div>{{ item.price }}</div>
                                    </div>
                                </div>
                                <div class="history-information2">
                                    <div class="history-id">#{{ totalCount - index }}</div>
                                    <div class="history-time">{{ item.timeago }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="history-actionBar" v-show="item.authentication == false">
                            <div class="history-button">
                                <span v-if="item.buyState === true&&item.state!==null&&item.state!==''" @click="bugNow1(item)">Buy now</span>
                            </div>
                            <div class="history-button"></div>
                        </div>
                        <div v-show="item.authentication == true">
                            <div class="history-actionBar" v-if="item.state === null || item.state === ''">
                                <div class="history-button" @click="goTransaction(item,'sell')">Sell</div>
                                <div class="history-button" @click="goTransaction(item,'Transfer')">Transfer</div>
                            </div>
                            <div class="history-actionBar" v-else-if="item.state === 'sale'">
                                <div class="history-button" @click="cancelSaleNew(item)">Cancel Listing</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="marketBox" v-show="currentIndex == 2" v-infinite-scroll="scrollBtn2" infinite-scroll-distance="50">
                <div class="historyList">
                    <div class="historyLi" v-for="(item, index) in allHistoryList" :key="index">
                        <div @click="goHistory(item)">
                            <div class="history-img"><img v-lazy="item.awsurl?item.awsurl:item.ipfsurl" /></div>
                            <div class="history-information-box">
                                <div class="history-information1">
                                    <div class="history-name">{{ item.title }}</div>
                                    <div class="history-price">
                                        <div class="history-price-logo">
                                            <img :src="chainImg[$store.getters.walletInfo.chainId]">
                                        </div>
                                        <div>{{ item.price }}</div>
                                    </div>
                                </div>
                                <div class="history-information2">
                                    <div class="history-id">#{{ total - index }}</div>
                                    <div class="history-time">{{ item.timeago }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="history-actionBar" v-show="item.authentication == false">
                            <div class="history-button">
                                <span v-if="item.buyState === true&&item.state!==null&&item.state!==''" @click="bugNow(item)">Buy now</span>
                            </div>
                            <div class="history-button">
                                <span v-if="item.followState === true" @click="follow(item)">Follow</span>
                            </div>
                        </div>
                        <div v-show="item.authentication == true">
                            <div class="history-actionBar" v-if="item.state === null || item.state === ''">
                                <div class="history-button" @click="goTransaction(item,'sell')">Sell</div>
                                <div class="history-button" @click="goTransaction(item,'Transfer')">Transfer</div>
                            </div>
                            <div class="history-actionBar" v-else-if="item.state === 'sale'">
                                <div class="history-button" @click="cancelSale1(item)">Cancel Listing</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { chainName, networkName, chainImg } from "../../web3/web3Config";
const wallet = require("../../web3/common/wallet.js");
const address = require("../../web3/web3Config.js");
import { queryHistoryMarket } from "../../web3/HistoryMarket";
import { createHistoryV1ERC721 } from "../../web3/HistoryV1ERC721";
import { getChainIdNetwork } from "../../web3/common/wallet";
import infiniteScroll from "vue-infinite-scroll"
// import store from "@/store";
export default {
    name: "market",
    inject: ["reload"],
    directives: { infiniteScroll },
    data() {
        return {
            networkName: networkName,
            baseUrlData: "",
            currentIndex: this.$route.query.tab || 2,
            options: [{
                    value: "Recently Minted",
                    label: "Recently Minted",
                },
                {
                    value: "Recently Sold",
                    label: "Recently Sold",
                },
                {
                    value: "Rrice:Low to High ",
                    label: "Rrice:Low to High ",
                },
                {
                    value: "Rrice:High to Low ",
                    label: "Rrice:High to Low ",
                },
                {
                    value: "Highest Last Sale",
                    label: "Highest Last Sale",
                },
                {
                    value: "Oldest",
                    label: "Oldest",
                },
            ],
            value: "",
            historyListQuery: {
                pagenum: 1,
                pagesize: 4,
            },
            historyListQuery1: {
                pagenum: 1,
                pagesize: 4,
            },
            historyListQuery2: {
                pagenum: 1,
                pagesize: 4,
            },
            allHistoryList: [],
            seriesList: [],
            seriesList1: [],
            itemData: {},
            seriesAdmin: "",
            total: "",
            pageCount: "",
            totalCount: "",
            chainName: chainName,
            HistoryERC721: "",
            HistorySeriesERC721: "",
            networkIsShow: "",
            chainImg: chainImg,
            busy: false,
            busy1: false,
            edit: '',
        };
    },
    computed: {
        mapState() {
            return this.$store.getters.walletInfo
        }
    },
    watch: {
        '$store.getters.initStatusComplete': {
            immediate: true,
            handler(flag) {
                if (flag) {
                    this.init();
                }
            }
        }
    },
    mounted() {},
    methods: {
        async init() {
            this.baseUrlData = localStorage.getItem("baseUrl");
            let res = '';
            if (this.mapState.chainId) {
                res = this.mapState.chainId
            } else {
                res = await getChainIdNetwork();
            }
            this.HistoryERC721 = address.address[this.chainName[res]].HistoryERC721;
            this.HistorySeriesERC721 = address.address[this.chainName[res]].HistorySeriesERC721;
            this.networkIsShow = this.networkName[res]
            this.queryAllHistory()

            if (this.$route.query.tab == 1) {
                this.queryGetseriesnfts()
            }
            if (this.$route.query.tab == 2) {
                this.queryAllHistory()
            }
            if (this.$route.query.tab === '0') {
                this.queryGetseriesinfos()
            }
        },


        scrollBtn() {
            if (this.seriesList.length === this.historyListQuery1.pagenum * this.historyListQuery1.pagesize && this.pageCount > this.historyListQuery1.pagenum * this.historyListQuery1.pagesize) {
                this.historyListQuery1.pagenum += 1
                this.queryGetseriesinfos()
            }
        },
        scrollBtn1() {
            if (this.seriesList1.length === this.historyListQuery2.pagenum * this.historyListQuery2.pagesize && this.totalCount > this.historyListQuery2.pagenum * this.historyListQuery2.pagesize) {
                this.historyListQuery2.pagenum += 1
                this.queryGetseriesnfts()
            }
        },
        scrollBtn2() {
            if (this.allHistoryList.length === this.historyListQuery.pagenum * this.historyListQuery.pagesize && this.total > this.historyListQuery.pagenum * this.historyListQuery.pagesize) {
                this.historyListQuery.pagenum += 1
                this.queryAllHistory()
            }
        },
        btn(key) {

            // if (this.$store.getters.walletInfo.chainId == 56) {
            //     if (key == 0 || key == 1) {
            //         return;
            //     }
            // }


            this.currentIndex = key;
            if (key == 0) {
                this.historyListQuery1.pagenum = 1
                this.historyListQuery1.pagesize = 4
                this.seriesList = []
                this.queryGetseriesinfos()
            }
            if (key == 1) {
                this.historyListQuery2.pagenum = 1
                this.historyListQuery2.pagesize = 4
                this.seriesList1 = []
                this.queryGetseriesnfts()
            }
            if (key == 2) {
                this.historyListQuery.pagenum = 1
                this.historyListQuery.pagesize = 4
                this.allHistoryList = []
                this.queryAllHistory()
            }
        },
        goMintList(id, admin, tokenaddress) {
            let stateAdmin = this.mapState.address.toLowerCase()
            if (admin == stateAdmin) {
                this.edit = true
                let isShow = true;
                let tokenAddress = tokenaddress
                this.$router.push({ path: "/series", query: { id, isShow, edit: this.edit, tokenAddress } });
            } else {
                let isShow = false;
                this.edit = false
                let tokenAddress = tokenaddress
                this.$router.push({ path: "/series", query: { id, isShow, edit: this.edit, tokenAddress } });
            }
        },
        goTransaction(item, view) {
            this.itemData = {
                tokenid: item.tokenid,
                price: 1,
                currentPage: 1,
            }
            let transactionState = view
            let historyItem = item
            let tokenaddress = item.tokenaddress
            this.$router.push({ path: '/transaction', query: { itemData: JSON.stringify(this.itemData), item: JSON.stringify(historyItem), transactionState: transactionState, tokenaddress: tokenaddress } })
        },
        cancelSale1(item) {
            let _this = this;
            let erc21 = queryHistoryMarket();
            if (this.mapState.chainId) {


                wallet.getWalletInfo().then((info) => {
                    erc21.sellNft(this.HistoryERC721, item.tokenid, 0).then(() => {
                        let params = {
                            admin: info.address.toLowerCase(),
                            tokenid: item.tokenid
                        };
                        this.$axios.post(this.baseUrlData + this.$api.post_cancelSale, params).then(() => {
                            this.$message({
                                message: 'Operation succeeded',
                                type: 'success'
                            });
                            _this.reload()
                        })
                    })
                })
            }
        },
        cancelSaleNew(item) {
            let _this = this;
            let erc21 = queryHistoryMarket();
            if (this.mapState.chainId) {

                wallet.getWalletInfo().then((info) => {
                    erc21.sellNft(item.tokenaddress == this.HistorySeriesERC721 ? this.HistorySeriesERC721 : item.tokenaddress, item.tokenid, 0).then(() => {
                        let params = {
                            admin: info.address.toLowerCase(),
                            tokenid: item.tokenid,
                            tokenaddress: item.tokenaddress
                        };
                        this.$axios.post(this.baseUrlData + this.$api.post_cancelseriessale, params).then(() => {
                            this.$message({
                                message: 'Operation succeeded',
                                type: 'success'
                            });
                            _this.reload()
                        })
                    })
                })
            }
        },
        isBuyFollow(item, index) {
            let _this = this;
            let ctr = queryHistoryMarket();
            let erc721 = createHistoryV1ERC721();
            if (this.mapState.chainId) {
                //is buy
                ctr.tokenSaleInfo(this.HistoryERC721, item.tokenid).then((buyState) => {
                    if (buyState.onSale === false) {
                        _this.allHistoryList[index].buyState = false;
                    } else {
                        _this.allHistoryList[index].buyState = true;
                    }
                    this.$forceUpdate();
                    //is follow
                    erc721.tokenEventId(item.tokenid).then((eventId) => {
                        //follow time
                        erc721.getConfig().then((mintStartTime) => {
                            erc721.eventData(eventId).then((firstMintTime) => {
                                let startTime =
                                    Number(mintStartTime._mintStartTime) +
                                    Number(firstMintTime.firstMintTime);
                                let endTime =
                                    Number(mintStartTime._mintEndTime) +
                                    Number(firstMintTime.firstMintTime);
                                if (
                                    Date.parse(new Date()).toString().substr(0, 10) >=
                                    startTime &&
                                    Date.parse(new Date()).toString().substr(0, 10) <= endTime
                                ) {
                                    _this.allHistoryList[index].followState = true;
                                } else {
                                    _this.allHistoryList[index].followState = false;
                                }
                                this.$forceUpdate();
                            });
                        });
                    });
                    console.log(_this.allHistoryList[index].followState);
                });
            }
        },
        isBuyFollow1(item, index) {
            let _this = this;
            let ctr = queryHistoryMarket();
            let erc721 = createHistoryV1ERC721();
            if (this.mapState.chainId) {
                //is buy
                ctr
                    .tokenSaleInfo(item.tokenaddress == this.HistorySeriesERC721 ? this.HistorySeriesERC721 : item.tokenaddress, item.tokenid)
                    .then((buyState) => {
                        if (buyState.onSale === false) {
                            _this.seriesList1[index].buyState = false;
                        } else {
                            _this.seriesList1[index].buyState = true;
                        }
                        this.$forceUpdate();
                        //is follow
                        erc721.tokenEventId(item.tokenid).then((eventId) => {
                            //follow time
                            erc721.getConfig().then((mintStartTime) => {
                                erc721.eventData(eventId).then((firstMintTime) => {
                                    let startTime =
                                        Number(mintStartTime._mintStartTime) +
                                        Number(firstMintTime.firstMintTime);
                                    let endTime =
                                        Number(mintStartTime._mintEndTime) +
                                        Number(firstMintTime.firstMintTime);
                                    if (
                                        Date.parse(new Date()).toString().substr(0, 10) >=
                                        startTime &&
                                        Date.parse(new Date()).toString().substr(0, 10) <= endTime
                                    ) {
                                        _this.seriesList1[index].followState = true;
                                    } else {
                                        _this.seriesList1[index].followState = false;
                                    }
                                    this.$forceUpdate();
                                });
                            });
                        });
                    });
            }
        },
        follow(item) {
            if (item.followState === true) {
                let ctr = createHistoryV1ERC721();
                if (this.mapState.chainId) {
                    wallet.getWalletInfo().then((info) => {
                        ctr.tokenEventId(item.tokenid).then((eventId) => {
                            //follow time
                            ctr.getConfig().then((mintStartTime) => {
                                ctr.eventData(eventId).then((firstMintTime) => {
                                    let startTime =
                                        Number(mintStartTime._mintStartTime) +
                                        Number(firstMintTime.firstMintTime);
                                    let endTime =
                                        Number(mintStartTime._mintEndTime) +
                                        Number(firstMintTime.firstMintTime);
                                    if (
                                        Date.parse(new Date()).toString().substr(0, 10) >=
                                        startTime &&
                                        Date.parse(new Date()).toString().substr(0, 10) <= endTime
                                    ) {
                                        ctr.followHistoryEvent(eventId).then((tokenid) => {
                                            let params = {
                                                admin: info.address.toLowerCase(),
                                                eventid: Number(eventId),
                                                tokenid: String(tokenid),
                                            };
                                            this.$axios
                                                .post(
                                                    this.baseUrlData + this.$api.post_followtoken,
                                                    params
                                                )
                                                .then(() => {
                                                    this.$message({
                                                        message: "Operation succeeded",
                                                        type: "success",
                                                    });
                                                    console.log("success: follow");
                                                });
                                        });
                                    } else {
                                        this.$message({
                                            message: "Allowed time exceeded",
                                            type: "warning",
                                        });
                                    }
                                });
                            });
                        });
                    });
                }
            }
        },
        bugNow(item) {
            if (item.buyState === true) {
                let ctr = queryHistoryMarket();
                if (this.mapState.chainId) {
                    wallet.getWalletInfo().then((info) => {
                        ctr
                            .tokenSaleInfo(this.HistoryERC721, item.tokenid)
                            .then((buyState) => {
                                if (buyState.onSale === false) {
                                    this.$message({
                                        message: "Not available for purchase",
                                        type: "warning",
                                    });
                                } else {
                                    ctr
                                        .buyNft(
                                            this.HistoryERC721,
                                            item.tokenid,
                                            info.address,
                                            buyState.price
                                        )
                                        .then(() => {
                                            let params = {
                                                admin: info.address.toLowerCase(),
                                                tokenowner: item.admin.toLowerCase(),
                                                tokenid: item.tokenid,
                                            };
                                            this.$axios
                                                .post(
                                                    this.baseUrlData + this.$api.post_buytoken,
                                                    params
                                                )
                                                .then(() => {
                                                    this.$message({
                                                        message: "Operation succeeded",
                                                        type: "success",
                                                    });
                                                    this.reload();
                                                    console.log("success: bugNow");
                                                });
                                        });
                                }
                            });
                    });
                }
            }
        },
        bugNow1(item) {
            if (item.buyState === true) {
                let ctr = queryHistoryMarket();
                if (this.mapState.chainId) {
                    wallet.getWalletInfo().then((info) => {
                        ctr.tokenSaleInfo(item.tokenaddress == this.HistorySeriesERC721 ? this.HistorySeriesERC721 : item.tokenaddress, item.tokenid).then((buyState) => {
                            if (buyState.onSale === false) {
                                this.$message({
                                    message: "Not available for purchase",
                                    type: "warning",
                                });
                            } else {
                                ctr.buyNft(item.tokenaddress == this.HistorySeriesERC721 ? this.HistorySeriesERC721 : item.tokenaddress, item.tokenid, info.address, buyState.price)
                                    .then(() => {
                                        let params = {
                                            admin: info.address.toLowerCase(),
                                            tokenowner: item.admin.toLowerCase(),
                                            tokenid: item.tokenid,
                                            network: this.mapState.network ? this.mapState.network : this.networkIsShow,
                                            tokenaddress: item.tokenaddress
                                        };
                                        this.$axios
                                            .post(
                                                this.baseUrlData + this.$api.post_buyseriestoken,
                                                params
                                            )
                                            .then(() => {
                                                this.$message({
                                                    message: "Operation succeeded",
                                                    type: "success",
                                                });
                                                this.reload();
                                                console.log("success: bugNow");
                                            });
                                    });
                            }
                        });
                    });
                }
            }
        },
        queryAllHistory() {
            let _this = this;
            let params = {
                pagenum: this.historyListQuery.pagenum,
                pagesize: this.historyListQuery.pagesize,
                network: this.mapState.network ? this.mapState.network : this.networkIsShow,
            };
            this.$axios
                .get(this.baseUrlData + this.$api.post_allHistory, params)
                .then((res) => {
                    if (!params.network) {
                        return;
                    }
                    _this.allHistoryList = this.allHistoryList.concat(res.result.list);
                    _this.total = res.result.total;
                    _this.allHistoryList.forEach((item, index) => {
                        if (item.admin == this.mapState.address.toLowerCase()) {
                            _this.allHistoryList[index].authentication = true;
                        } else {
                            _this.allHistoryList[index].authentication = false;
                        }
                        //Initialize forbidden state
                        _this.allHistoryList[index].buyState = false;
                        _this.allHistoryList[index].followState = false;
                        //query state buy and follow
                        _this.isBuyFollow(item, index);
                    });
                });
        },

        queryGetseriesinfos() {
            let params = {
                pagenum: this.historyListQuery1.pagenum,
                pagesize: this.historyListQuery1.pagesize,
                network: this.mapState.network ? this.mapState.network : this.networkIsShow,
            };
            this.$axios.get(this.baseUrlData + this.$api.post_getseriesinfos, params).then((res) => {
                if (!params.network) {
                    return;
                }
                this.seriesList = this.seriesList.concat(res.result.list);
                this.pageCount = res.result.total;
                this.seriesList.forEach((item) => {
                    let address = item.admin;
                    let startText = address.substring(0, 5);
                    let endText = address.substring(address.length - 4, address.length);
                    this.seriesAdmin = startText + "..." + endText;
                });
            });
        },
        queryGetseriesnfts() {
            let _this = this;
            let params = {
                pagenum: this.historyListQuery2.pagenum,
                pagesize: this.historyListQuery2.pagesize,
                network: this.mapState.network ? this.mapState.network : this.networkIsShow,
                seriesid: "",
                admin: "",
            };
            this.$axios
                .get(this.baseUrlData + this.$api.post_getseriesnfts, params)
                .then((res) => {
                    if (!params.network) {
                        return;
                    }
                    this.seriesList1 = this.seriesList1.concat(res.result.list);
                    this.totalCount = res.result.total;
                    this.seriesList1.forEach((item, index) => {
                        if (item.admin == this.mapState.address.toLowerCase()) {
                            _this.seriesList1[index].authentication = true;
                        } else {
                            _this.seriesList1[index].authentication = false;
                        }
                        let address = item.admin;
                        let startText = address.substring(0, 5);
                        let endText = address.substring(address.length - 4, address.length);
                        this.seriesAdmin = startText + "..." + endText;
                        _this.seriesList1[index].buyState = false;
                        _this.seriesList1[index].followState = false;
                        _this.isBuyFollow1(item, index);
                    });
                });
        },
        goHistory(item) {
            if (item.admin == this.mapState.address.toLowerCase()) {
                this.$router.push({
                    path: `/history/?tokenId=${item.tokenid}&tokenaddress=${item.tokenaddress}&actionsState=false`,
                });
            } else {
                this.$router.push({
                    path: `/history/?tokenId=${item.tokenid}&tokenaddress=${item.tokenaddress}&actionsState=true`,
                });
            }
        },
        goHistory1(item) {
            if (item.admin == this.mapState.address.toLowerCase()) {
                this.$router.push({
                    path: `/history/?tokenIdData=${item.tokenid}&tokenaddress=${item.tokenaddress}&actionsState=false`,
                });
            } else {
                this.$router.push({
                    path: `/history/?tokenIdData=${item.tokenid}&tokenaddress=${item.tokenaddress}&actionsState=true`,
                });
            }
        },
    },
};
</script>
<style lang="scss" src="../../assets/css/marketPage.scss" scoped></style>
<style lang="scss" scoped>
@media (max-width: 1125px) {

    .myNFTs {
        margin: 3.68641rem 0 0;
        padding: 1.3312rem .768rem;

        .tabBar {
            margin: 0 0 .8192rem;

            .tabBar-title {
                /*font-family: 'Poppins';*/
                font-style: normal;
                font-weight: 600;
                font-size: 0.9216rem;
                line-height: 1.5872rem;
                text-align: left;
                color: #04111D;
                margin-bottom: 0.8192rem;
            }

            .tabBar-box {
                margin: 0;
                justify-content: space-between;
                padding: 0 .512rem;


                .Single.Series {}

                .Series {
                    margin: 0;
                    padding: 0 .2048rem;
                    width: auto;
                    height: 2.86721rem;
                    line-height: 2.66241rem;
                    position: relative;
                    border: 0;

                    /*font-family: 'Poppins';*/
                    font-style: normal;
                    font-weight: 600;
                    font-size: .8192rem;
                    /* identical to box height, or 150% */
                    color: #707A83;
                    white-space: nowrap;


                    &.currentIndexClass {
                        color: #353840;

                        &:after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            height: .2048rem;
                            background: #FFE161;
                            border-radius: .512rem .512rem 0px 0px;
                            left: 0;
                        }

                    }


                }
            }
        }

        #seriesBox,
        #marketBox {
            padding: 0;

            .series-list {
                .series-li {
                    .series-list-img-box {
                        .series-list-img1 {
                            img {}
                        }
                    }

                    .series-list-text-box {
                        .series-list-name {}

                        .series-list-name1 {
                            .by-name {}

                            .name-info {}
                        }

                        .series-list-text {}
                    }
                }
            }

            .historyList {
                justify-content: space-between;

                .historyLi {
                    width: 8.60162rem;
                    height: 16.02564rem;
                    margin: 0 0 .6144rem 0;
                    overflow: hidden;
                    border-radius: .4096rem;


                    .history-img {
                        img {
                            // width: 8.60162rem;
                            height: 9.47202rem;
                        }

                    }

                    .history-information-box {
                        padding: .4096rem;
                        border-radius: 0;

                        .history-information1 {
                            /*font-family: 'Poppins';*/
                            font-style: normal;
                            font-weight: 500;
                            font-size: .7168rem;
                            line-height: 1.0752rem;
                            /* identical to box height */
                            color: #353840;
                            padding: 0;
                            align-items: center;
                            justify-content: space-between;

                            display: block;

                            .history-name {
                                max-width: 100%;
                                margin-bottom: .3072rem;
                            }

                            .history-price {
                                margin: 0 0 .3072rem 0;
                                max-width: none;

                                /*font-family: 'Poppins';*/
                                font-style: normal;
                                font-weight: 500;
                                font-size: .7168rem;
                                line-height: 1.0752rem;
                                /* identical to box height */
                                text-align: right;
                                color: #353840;
                                display: flex;
                                align-items: center;
                                // justify-content: center;
                                width: 100%;



                                .history-price-logo {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-right: .2048rem;

                                    img {
                                        width: auto;
                                        height: .7168rem;
                                        display: block;
                                    }
                                }
                            }
                        }

                        .history-information2 {
                            /*font-family: 'Poppins';*/
                            font-style: normal;
                            font-weight: 400;
                            font-size: .6144rem;
                            line-height: .9216rem;
                            /* identical to box height */
                            color: #707A83;
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .history-id {
                                max-width: none;
                            }

                            .history-time {
                                margin: 0;
                            }
                        }
                    }



                    .history-actionBar {
                        height: 1.8944rem;
                        align-items: center;
                        padding: 0 .4096rem;
                        justify-content: space-between;


                        .history-button {
                            /*font-family: 'Poppins';*/
                            font-style: normal;
                            font-weight: 500;
                            font-size: .7168rem;
                            line-height: 1.0752rem;
                            /* identical to box height */
                            color: #FFC107;
                            display: block;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }






    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
}
</style>